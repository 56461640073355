import React from "react";
import { Row, Col, Tooltip } from "antd";
import {
  FacebookFilled,
  TwitterOutlined,
  YoutubeFilled,
  InstagramFilled,
  // MailOutlined,
  LinkedinFilled
} from "@ant-design/icons";
import { Link } from "gatsby";
import "../components/layout.css";
import { Footer } from "antd/lib/layout/layout";
import FooterJsonData from "../data/footer.json";
import Logo from "../images/cwsolutions.png";

const FooterComp = () => {
  return (
    <Footer style={{ background: "#313131" }}>
      <Row style={{ justifyContent: "space-evenly", paddingLeft: "5%" }}>
        {FooterJsonData.footerContent.map((item) => (
          <Col
            xs={{ span: 24 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            className="footerContent"
          >
            <h4>{item.title}</h4>
            {item.footerMenu.map((subItem) => (
              <p key={subItem.name} style={{marginBottom: "0.8rem"}}>
                {subItem.path ? (
                  <Link className="footerSubmenuLink" to={`/${subItem.path}`}>
                    {subItem.name}
                  </Link>
                ) : (
                  <span className="footerSubmenuLink">{subItem.name}</span>
                )}
              </p>
            ))}
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="websiteLinks">
            <Link to="https://www.facebook.com/cwerpsolutions/" target="_blank">
              <Tooltip title="Facebook">
                <FacebookFilled className="footerIcons" />
              </Tooltip>
            </Link>
            <Link to="https://www.linkedin.com/company/cwsolutions/" target="_blank">
              <Tooltip title="Linkedin">
                <LinkedinFilled className="footerIcons" />
              </Tooltip>
            </Link>
            <Link to="https://twitter.com/CwerpSolutions" target="_blank">
              <Tooltip title="Twitter">
                <TwitterOutlined className="footerIcons" />
              </Tooltip>
            </Link>
            <Link to="https://www.youtube.com/channel/UC0wLykv1AkMd1ecUjRjbtwA" target="_blank">
              <Tooltip title="Youtube">
                <YoutubeFilled className="footerIcons" />
              </Tooltip>
            </Link>
            <Link to="https://www.instagram.com/" target="_blank">
              <Tooltip title="Instagram">
                <InstagramFilled className="footerIcons" />
              </Tooltip>
            </Link>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

export default FooterComp;
