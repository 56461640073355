import React, { useState, useEffect } from "react";

export const useViewport = () => {
  const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 1300);
  // const [width, setWidth] = useState(window.innerWidth);
// console.log('width', width)
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
	if(typeof window !== "undefined") {
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}
  }, []);

  return { width };
};
