import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Layout, Menu, Radio, Row, Col, Drawer, Button } from "antd";
import { DesktopOutlined, MenuOutlined, DownOutlined, CaretDownOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./globalStyles.css";
import "./style.css";
import MenuJsonData from "../data/menu.json";
import { Navbar, Nav, NavDropdown, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../images/cwsolutions.png";
import { useViewport } from "../components/useViewport";

const { Header } = Layout;
const HeaderComp = ({ siteTitle }) => {
  const { width } = useViewport();

  let initialShowMenu = {
    solutions: false,
    services: false,
    company: false,
    more: false,
  };
  const [showMenu, setShowMenu] = useState(initialShowMenu);

  const handleShowMenu = (value) => {
    let modifiedShowMenu = { ...initialShowMenu };
    if (value === "Solutions") {
      modifiedShowMenu.solutions = true;
    } else if (value === "Services") {
      modifiedShowMenu.services = true;
    } else if (value === "Company") {
      modifiedShowMenu.company = true;
    } else if (value === "Platform") {
      modifiedShowMenu.platform = true;
    }
    setShowMenu({ ...modifiedShowMenu });
  };

  return (
    <div className="headerDiv">
      <Navbar expand="xl">
        <Navbar.Brand href="/">
          <img src={Logo} alt="cwlogo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {MenuJsonData.menu.map((item, index) =>
              item.subMenu ? (
                width > 1024 ? (
                  <NavDropdown
                    title={item.title}
                    id="basic-nav-dropdown"
                    onMouseEnter={() => handleShowMenu(item.title)}
                    show={
                      item.title == "Solutions"
                        ? showMenu.solutions
                        : item.title == "Services"
                        ? showMenu.services
                        : item.title == "Company"
                        ? showMenu.company
                        : item.title == "Platform"
                        ? showMenu.platform
                        : false
                    }
                    onMouseLeave={() => setShowMenu({ ...initialShowMenu })}
                    className="mainMenu"
                  >
                    {width > 1024 ? (
                      <Row
                        style={
                          item.title == "Solutions" && width > 1024
                            ? { width: "500px" }
                            : {}
                        }
                      >
                        {item.subMenu.map((subItem, index) =>
                          subItem.list ? (
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 12 }}
                              xxl={{ span: 12 }}
                              className= "borderRight"
                            > 
                                <NavDropdown.Item className={subItem.title == "ERP" ? "subMenuCardsTitle" : "subMenuCardsNoTitle"}>
                                  <Link
                                    className="subMenuTitleLink"
                                    to={`/${subItem.path}`}
                                  >
                                    <span>{subItem.title}</span> {subItem.title == "ERP" ? <CaretDownOutlined className="caretDownIcon" /> : ""}
                                  </Link>
                                </NavDropdown.Item>
                              <ListGroup style={{paddingLeft: subItem.title == "ERP" ? "20px" : ""}}>
                                {subItem.list.map((listItem, listItemIndex) => (
                                  <ListGroup.Item className={subItem.title !== "ERP" && listItem.name == "Point Of Sale"  ? "subMenuTitleDesc" : "subMenuDesc"}>
                                    <Link
                                      className={subItem.title == "ERP" ? "subMenuLink" : "subMenuTitleLink"}
                                      to={`/${listItem.path}`}
                                    >
                                      {listItem.name}
                                    </Link>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Col>
                          ) : (
                            <NavDropdown.Item className="subMenuDesc">
                              <Link
                                className="subMenuTitleLink"
                                to={`/${subItem.path}`}
                              >
                                {subItem.title}
                              </Link>
                            </NavDropdown.Item>
                          )
                        )}
                      </Row>
                    ) : (
                      item.subMenu.map((subItem, index) =>
                        subItem.list ? (
                          <NavDropdown
                            title={<span>- {subItem.title}</span>}
                            id="basic-nav-dropdown"
                            className="subMenuCardsTitle"
                          >
                            <ListGroup>
                              {subItem.list.map((listItem, listItemIndex) => (
                                <ListGroup.Item className="subMenuDesc border-bottom">
                                  <Link
                                    className="subMenuLink"
                                    to={`/${listItem.path}`}
                                  >
                                    -- {listItem.name}
                                  </Link>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </NavDropdown>
                        ) : (
                          <NavDropdown.Item className="subMenuDesc">
                            <Link
                              className="subMenuLink"
                              to={`/${subItem.path}`}
                            >
                              {subItem.title}
                            </Link>
                          </NavDropdown.Item>
                        )
                      )
                    )}
                  </NavDropdown>
                ) : (
                  <NavDropdown title={item.title} id="basic-nav-dropdown">
                    {width > 1024 ? (
                      <Row
                        style={
                          item.title == "Solutions" && width > 1024
                            ? { width: "800px" }
                            : {}
                        }
                      >
                        {item.subMenu.map((subItem, index) =>
                          subItem.list ? (
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 8 }}
                              xl={{ span: 8 }}
                              xxl={{ span: 8 }}
                              className= "borderRight"
                            >
                                <NavDropdown.Item className="subMenuCardsTitle">
                                  <Link
                                    className="subMenuTitleLink"
                                    to={`/${subItem.path}`}
                                  >
                                    {subItem.title}
                                  </Link>
                                </NavDropdown.Item>
                              <ListGroup>
                                {subItem.list.map((listItem, listItemIndex) => (
                                  <ListGroup.Item className="subMenuDesc">
                                    <Link
                                      className="subMenuLink"
                                      to={`/${listItem.path}`}
                                    >
                                      {listItem.name}
                                    </Link>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Col>
                          ) : (
                            <NavDropdown.Item className="subMenuDesc">
                              <Link
                                className="subMenuLink"
                                to={`/${subItem.path}`}
                              >
                                {subItem.title}
                              </Link>
                            </NavDropdown.Item>
                          )
                        )}
                      </Row>
                    ) : (
                      item.subMenu.map((subItem, index) =>
                        subItem.list ? (
                          subItem.title == "ERP" ? (
                            <NavDropdown
                              title={<span>- {subItem.title}</span>}
                              className="erpDropdown"
                            >
                              {subItem.list.map((listItem, listItemIndex) => (
                                <NavDropdown.Item className="subMenuDesc">
                                  <Link
                                    className="menuLink"
                                    to={`/${listItem.path}`}
                                  >
                                    -- {listItem.name}
                                  </Link>
                                </NavDropdown.Item>
                              ))}
                            </NavDropdown>
                          ) : (
                            <ListGroup>
                              {subItem.list.map((listItem, listItemIndex) => (
                                <ListGroup.Item className="subMenuDesc">
                                  <Link
                                    className="menuLink"
                                    to={`/${listItem.path}`}
                                  >
                                    -- {listItem.name}
                                  </Link>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          )
                        ) : (
                          <NavDropdown.Item className="subMenuDesc">
                            <Link
                              className="subMenuLink"
                              to={`/${subItem.path}`}
                            >
                              -- {subItem.title}
                            </Link>
                          </NavDropdown.Item>
                        )
                      )
                    )}
                  </NavDropdown>
                )
              ) : (
                <Nav.Link>
                  {item.title == "REQUEST A DEMO" ? (
                    <Link to={`/${item.path}`} className="menuLink">
                      {width > 1024 ? (
                        <Radio.Group>
                          <Radio.Button>
                            <DesktopOutlined
                              style={{ verticalAlign: "baseline" }}
                            />
                          </Radio.Button>
                          <Radio.Button>{item.title}</Radio.Button>
                        </Radio.Group>
                      ) : (
                        item.title.charAt(0).toUpperCase() +
                        item.title.slice(1).toLowerCase()
                      )}
                    </Link>
                  ) : (
                    <div>
                      <Link className="menuLink" to={`/${item.path}`}>
                        {item.title}
                      </Link>
                    </div>
                  )}
                </Nav.Link>
              )
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default HeaderComp;
